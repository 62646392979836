import { defineStore } from 'pinia';
import type { MemberBaseApiModel } from '@groenhart/api';
import type { Usp } from '@/types/usp';

export const useGeneralStore = defineStore('general-store', () => {
  const { status } = useAuth();
  const loading = ref<boolean>(false);
  const isNewsletterSubscribed = ref<boolean>(false);
  const showProductsExclVatCookie = useCookie<boolean>('show-products-excl-vat', {
    expires: new Date('3000/01/1'),
  });

  const member = ref<MemberBaseApiModel | null>();

  // Use expires so that the cookie is not deleted when the browser is closed
  const firstTimeVisit = useCookie<boolean>('first-time-visit', {
    expires: new Date('3000/01/1'),
  });

  const usps = ref<Usp[]>([]);

  if (firstTimeVisit.value === undefined) {
    firstTimeVisit.value = status.value !== 'authenticated';
  }

  if (showProductsExclVatCookie.value === undefined) {
    showProductsExclVatCookie.value = true;
  }

  const showProductsExclVat = computed({
    get() {
      if (status.value === 'unauthenticated') {
        return true;
      }

      return showProductsExclVatCookie.value;
    },
    set(newValue) {
      showProductsExclVatCookie.value = newValue;
    },
  });

  return {
    isNewsletterSubscribed,
    showProductsExclVat,
    showProductsExclVatCookie,
    firstTimeVisit,
    usps,
    loading,
    member,
  };
});
