/* tslint:disable */
/* eslint-disable */
/**
 * Groenhart API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0.30.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface LocationContactPersonApiModel
 */
export interface LocationContactPersonApiModel {
    /**
     * 
     * @type {string}
     * @memberof LocationContactPersonApiModel
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LocationContactPersonApiModel
     */
    imageUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LocationContactPersonApiModel
     */
    jobTitle?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LocationContactPersonApiModel
     */
    phoneNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LocationContactPersonApiModel
     */
    email?: string | null;
}

/**
 * Check if a given object implements the LocationContactPersonApiModel interface.
 */
export function instanceOfLocationContactPersonApiModel(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function LocationContactPersonApiModelFromJSON(json: any): LocationContactPersonApiModel {
    return LocationContactPersonApiModelFromJSONTyped(json, false);
}

export function LocationContactPersonApiModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): LocationContactPersonApiModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'imageUrl': !exists(json, 'imageUrl') ? undefined : json['imageUrl'],
        'jobTitle': !exists(json, 'jobTitle') ? undefined : json['jobTitle'],
        'phoneNumber': !exists(json, 'phoneNumber') ? undefined : json['phoneNumber'],
        'email': !exists(json, 'email') ? undefined : json['email'],
    };
}

export function LocationContactPersonApiModelToJSON(value?: LocationContactPersonApiModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'imageUrl': value.imageUrl,
        'jobTitle': value.jobTitle,
        'phoneNumber': value.phoneNumber,
        'email': value.email,
    };
}

