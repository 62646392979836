export default defineNuxtRouteMiddleware((to) => {
  const { status } = useAuth();
  const cookie = useCookie('redirect-url');

  if (cookie.value) {
    if (to.path === routes.login.path && status.value !== 'authenticated'){
      return;
    }
    
    const redirectUrl = cookie.value;
    cookie.value = null;

    // This redirect is only relevant after authentication. Don't redirect when the user goes to forgot password or the register page without logging in.
    if (status.value !== 'authenticated') {
      return;
    }

    if (to.path !== redirectUrl) {
      return navigateTo(redirectUrl);
    }
  }
});
