import { AppInsightsPlugin } from 'vue3-application-insights';

export default defineNuxtPlugin((nuxtApp) => {
  if (process.server) {
    return;
  }

  const router = useRouter();
  const runtimeConfig = useRuntimeConfig();

  if (!runtimeConfig.public.asConnectionString) {
    return;
  }

  nuxtApp.vueApp.use(AppInsightsPlugin, {
    appName: 'groenhart',
    connectionString: runtimeConfig.public.asConnectionString,
    router,
    trackAppErrors: true,
  });
});
