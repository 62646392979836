export const routes = {
  addresses: { name: 'addresses', path: '/mijn-groenhart/addresses' },
  home: { name: 'index', path: '/' },
  categories: { name: 'categories', path: '/categories' },
  cart: { name: 'cart', path: '/cart' },
  locations: { name: 'locations', path: '/locations' },
  news: { name: 'news', path: '/news' },
  sales: { name: 'sales', path: '/sales' },
  search: { name: 'search', path: '/search' },
  login: { name: 'login', path: '/login' },
  products: { name: 'products', path: '/products' },
  checkout: { name: 'checkout', path: '/checkout' },
  myAccount: { name: 'myAccount', path: '/mijn-groenhart' },
  register: { name: 'register', path: '/register' },
  forgotPassword: { name: 'forgotPassword', path: '/forgot-password' },
  registerCheckout: { name: 'registerCheckout', path: '/register/checkout' },
  createAccount: { name: 'createAccount', path: '/register/create-account' },
  registerSuccessful: { name: 'registerSuccessful', path: '/register/confirmation' },
  activateAccountIndex: {
    name: 'activateAccountIndex',
    path: '/register/activate-account',
  },
  activateAccountActivate: {
    name: 'activateAccountActivate',
    path: '/register/activate-account/activate',
  },
  activateAccountSuccess: {
    name: 'activateAccountSuccess',
    path: '/register/activate-account/confirmation',
  },
  activateAccountSuccessNoInvoice: {
    name: 'activateAccountSuccessNoInvoice',
    path: '/register/activate-account/in-progress',
  },
  scanner: { name: 'scanner', path: '/scanner' },
  onboarding: { name: 'onboarding', path: '/onboarding' },
  invoices: { name: 'invoices', path: '/mijn-groenhart/invoices' },
  pay: { name: 'pay', path: '/mijn-groenhart/invoices/pay' },
  wishlist: { name: 'wishlist', path: '/mijn-groenhart/wishlist' },
  orderHistory: { name: 'orderHistory', path: '/mijn-groenhart/order-history' },
  orders: { name: 'orders', path: '/mijn-groenhart/orders' },
  personalDetails: { name: 'personalDetails', path: '/mijn-groenhart/personal-details' },
  projects: { name: 'myProjects', path: '/mijn-groenhart/projects' },
} as const;
