export function useGtmEvents() {
  const gtm = useGtm();

  function gtmRegisterStepEvent(step: number, stepName: string) {
    if (gtm) {
      gtm.push({
        event: 'account_register',
        account_step: `Stap ${step}`,
        account_step_name: stepName,
      });
    }
  }

  function gtmErrorEvent(errorType: string, form: string) {
    if (gtm) {
      gtm.push({
        event: 'error_event',
        error_type: errorType,
        formulier: form,
      });
    }
  }

  return {
    gtmRegisterStepEvent,
    gtmErrorEvent,
  };
}
