enum MessageType {
  Raw = 0,
  InvokeMethod = 1,
}

export function SendRawMessageToDotNet(message: string) {
  SendMessageToDotNet(MessageType.Raw, message);
}

export function SendInvokeMessageToDotNet(methodName: string, ...paramValues: any) {
  if (typeof paramValues !== 'undefined') {
    if (!Array.isArray(paramValues)) {
      paramValues = [paramValues];
    }
    for (let i = 0; i < paramValues.length; i++) {
      paramValues[i] = JSON.stringify(paramValues[i]);
    }
  }

  SendMessageToDotNet(
    MessageType.InvokeMethod,
    JSON.stringify({ MethodName: methodName, ParamValues: paramValues })
  );
}

function SendMessageToDotNet(messageType: MessageType, messageContent: any) {
  const message = JSON.stringify({ MessageType: messageType, MessageContent: messageContent });

  if (window.chrome && window.chrome.webview) {
    // Windows WebView2
    window.chrome.webview.postMessage(message);
  } else if (
    window.webkit &&
    window.webkit.messageHandlers &&
    window.webkit.messageHandlers.webwindowinterop
  ) {
    // iOS and MacCatalyst WKWebView
    window.webkit.messageHandlers.webwindowinterop.postMessage(message);
  } else {
    // When this is a normal browser, this does not exist.
    if (typeof hybridWebViewHost === 'undefined') {
      return;
    }
    // Android WebView
    hybridWebViewHost.sendMessage(message);
  }
}
