import { DefaultConfig, BasketsApi } from '@groenhart/api';
import type {
  BasketApiModel,
  BasketsBasketIdAddProductsPostRequest,
  BasketsBasketIdShipmentPutRequest,
  OrderLineApiModel,
  BasketsGetCorrectBasketAfterLoginGetRequest,
  BasketsBasketIdOrderLinesDeleteRequest,
  BasketsBasketIdExecuteBasketPipelinePostRequest,
} from '@groenhart/api';
import { ApiBase } from './base.api';

export function useBasketApi() {
  const runtimeConfig = useRuntimeConfig();
  const headers = useApiHeaders();

  const apiBase = new ApiBase(runtimeConfig.public.apiBase);
  DefaultConfig.config = apiBase.getDefaultConfig();

  const basketApi = new BasketsApi();

  async function initBasket() {
    return await basketApi.basketsPut({ headers });
  }

  async function getBasketById(id: string) {
    return await basketApi.basketsIdGetRaw({ id }, { headers });
  }

  async function getMemberBasket() {
    return await basketApi.basketsMemberGetRaw({ headers });
  }

  async function deleteOrderLinesFromBasket({ basketId }: BasketsBasketIdOrderLinesDeleteRequest) {
    return await basketApi.basketsBasketIdOrderLinesDelete({ basketId });
  }

  async function addToBasket({
    basketId,
    quantity,
    sku,
  }: {
    basketId: BasketApiModel['id'];
    quantity: OrderLineApiModel['quantity'];
    sku: OrderLineApiModel['sku'];
  }) {
    if (!basketId || !quantity || !sku) {
      return;
    }

    return await basketApi.basketsBasketIdAddProductPost({ basketId, quantity, sku }, { headers });
  }

  async function deleteFromBasket({
    basketId,
    orderLineId,
  }: {
    basketId: BasketApiModel['id'];
    orderLineId: OrderLineApiModel['id'];
  }) {
    if (!basketId || !orderLineId) {
      return;
    }

    return await basketApi.basketsBasketIdOrderLinesOrderLineIdDeleteRaw(
      { basketId, orderLineId },
      { headers }
    );
  }

  async function updateOrderLine({
    basketId,
    orderLineId,
    newQuantity,
  }: {
    basketId: BasketApiModel['id'];
    orderLineId: OrderLineApiModel['id'];
    newQuantity: OrderLineApiModel['quantity'];
  }) {
    if (!basketId || !orderLineId || !newQuantity) {
      return;
    }

    return await basketApi.basketsBasketIdUpdateQuantityPost(
      {
        basketId,
        orderLineId,
        newQuantity,
      },
      { headers }
    );
  }

  async function addVoucherCode({
    basketId,
    code,
  }: {
    basketId: BasketApiModel['id'];
    code: string;
  }) {
    if (!basketId || !code) {
      return;
    }

    return await basketApi.basketsBasketIdVoucherCodeCodePut({ basketId, code }, { headers });
  }

  async function deleteVoucherCode({
    basketId,
    code,
  }: {
    basketId: BasketApiModel['id'];
    code: string;
  }) {
    if (!basketId || !code) {
      return;
    }

    return await basketApi.basketsBasketIdVoucherCodeCodeDelete({ basketId, code }, { headers });
  }

  async function updateShipment({
    basketId,
    countryId,
    shippingMethodId,
  }: BasketsBasketIdShipmentPutRequest) {
    return await basketApi.basketsBasketIdShipmentPut(
      { basketId, countryId, shippingMethodId },
      { headers }
    );
  }

  async function addMultipleProductsToBasket({
    basketId,
    requestBody,
  }: BasketsBasketIdAddProductsPostRequest) {
    return await basketApi.basketsBasketIdAddProductsPost({ basketId, requestBody }, { headers });
  }

  async function getCorrectBasketAfterLogin({
    appBasketId,
  }: BasketsGetCorrectBasketAfterLoginGetRequest) {
    return await basketApi.basketsGetCorrectBasketAfterLoginGetRaw({ appBasketId }, { headers });
  }

  async function executeBasketPipeline({
    basketId,
  }: BasketsBasketIdExecuteBasketPipelinePostRequest) {
    return await basketApi.basketsBasketIdExecuteBasketPipelinePostRaw({ basketId }, { headers });
  }

  return {
    initBasket,
    getBasketById,
    getMemberBasket,
    addToBasket,
    deleteFromBasket,
    updateOrderLine,
    addVoucherCode,
    deleteVoucherCode,
    deleteOrderLinesFromBasket,
    updateShipment,
    addMultipleProductsToBasket,
    getCorrectBasketAfterLogin,
    executeBasketPipeline,
  };
}
