import revive_payload_client_4sVQNw7RlN from "C:/Dev/Git/Groenhart.Webshop.App/groenhart-app/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import router_jmwsqit4Rs from "C:/Dev/Git/Groenhart.Webshop.App/groenhart-app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import plugin_vue3_A0OWXRrUgq from "C:/Dev/Git/Groenhart.Webshop.App/groenhart-app/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "C:/Dev/Git/Groenhart.Webshop.App/groenhart-app/.nuxt/components.plugin.mjs";
import unhead_KgADcZ0jPj from "C:/Dev/Git/Groenhart.Webshop.App/groenhart-app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import prefetch_client_5tzzN0oIVL from "C:/Dev/Git/Groenhart.Webshop.App/groenhart-app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_tbFNToZNim from "C:/Dev/Git/Groenhart.Webshop.App/groenhart-app/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import plugin_AOuQ1DYzjk from "C:/Dev/Git/Groenhart.Webshop.App/groenhart-app/node_modules/@sidebase/nuxt-auth/dist/runtime/plugin.mjs";
import chunk_reload_client_UciE0i6zes from "C:/Dev/Git/Groenhart.Webshop.App/groenhart-app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import alert_1l4BSaq8Wt from "C:/Dev/Git/Groenhart.Webshop.App/groenhart-app/plugins/alert.ts";
import application_insights_YwuEgFiKgo from "C:/Dev/Git/Groenhart.Webshop.App/groenhart-app/plugins/application-insights.ts";
import bottom_sheet_ZP5ntcLxQW from "C:/Dev/Git/Groenhart.Webshop.App/groenhart-app/plugins/bottom-sheet.ts";
import lottie_q1asFBKAuo from "C:/Dev/Git/Groenhart.Webshop.App/groenhart-app/plugins/lottie.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  router_jmwsqit4Rs,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  unhead_KgADcZ0jPj,
  prefetch_client_5tzzN0oIVL,
  plugin_tbFNToZNim,
  plugin_AOuQ1DYzjk,
  chunk_reload_client_UciE0i6zes,
  alert_1l4BSaq8Wt,
  application_insights_YwuEgFiKgo,
  bottom_sheet_ZP5ntcLxQW,
  lottie_q1asFBKAuo
]