import validate from "C:/Dev/Git/Groenhart.Webshop.App/groenhart-app/node_modules/nuxt/dist/pages/runtime/validate.js";
import auth_45redirect_45global from "C:/Dev/Git/Groenhart.Webshop.App/groenhart-app/middleware/auth-redirect.global.ts";
import first_45visit_45global from "C:/Dev/Git/Groenhart.Webshop.App/groenhart-app/middleware/first-visit.global.ts";
import gtm_45page_45view_45global from "C:/Dev/Git/Groenhart.Webshop.App/groenhart-app/middleware/gtm-page-view.global.ts";
import refresh_45session_45global from "C:/Dev/Git/Groenhart.Webshop.App/groenhart-app/middleware/refresh-session.global.ts";
export const globalMiddleware = [
  validate,
  auth_45redirect_45global,
  first_45visit_45global,
  gtm_45page_45view_45global,
  refresh_45session_45global
]
export const namedMiddleware = {
  authentication: () => import("C:/Dev/Git/Groenhart.Webshop.App/groenhart-app/middleware/authentication.ts"),
  checkout: () => import("C:/Dev/Git/Groenhart.Webshop.App/groenhart-app/middleware/checkout.ts"),
  auth: () => import("C:/Dev/Git/Groenhart.Webshop.App/groenhart-app/node_modules/@sidebase/nuxt-auth/dist/runtime/middleware/auth.mjs")
}