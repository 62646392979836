import type { AlertProps } from '@/components/alerts/Alert.vue';
import { useAlertStore } from '@/store/alert.store';

export default defineNuxtPlugin(() => {
  return {
    provide: {
      toast: ({ alertText, link, duration, type }: AlertProps) => {
        const store = useAlertStore();
        store.isOpen = true;
        store.props = { alertText, link, duration, type };
      },
    },
  };
});
