import { default as _91id_9360g4KkWYonMeta } from "C:/Dev/Git/Groenhart.Webshop.App/groenhart-app/pages/brand/[id].vue?macro=true";
import { default as cart4Cclz5iO2CMeta } from "C:/Dev/Git/Groenhart.Webshop.App/groenhart-app/pages/cart.vue?macro=true";
import { default as _91category_93ROlMKDZQjCMeta } from "C:/Dev/Git/Groenhart.Webshop.App/groenhart-app/pages/categories/[category].vue?macro=true";
import { default as indexXcmxS4wYHLMeta } from "C:/Dev/Git/Groenhart.Webshop.App/groenhart-app/pages/categories/index.vue?macro=true";
import { default as _91subcategory_93WmMOvqko3oMeta } from "C:/Dev/Git/Groenhart.Webshop.App/groenhart-app/pages/categories/products/[subcategory].vue?macro=true";
import { default as confirmationi15Vp1kfspMeta } from "C:/Dev/Git/Groenhart.Webshop.App/groenhart-app/pages/checkout/confirmation.vue?macro=true";
import { default as indexm9zHqwuC9aMeta } from "C:/Dev/Git/Groenhart.Webshop.App/groenhart-app/pages/checkout/index.vue?macro=true";
import { default as indexGmAmBjGt0YMeta } from "C:/Dev/Git/Groenhart.Webshop.App/groenhart-app/pages/forgot-password/index.vue?macro=true";
import { default as reset3UT5sJbtYQMeta } from "C:/Dev/Git/Groenhart.Webshop.App/groenhart-app/pages/forgot-password/reset.vue?macro=true";
import { default as index6jYm0c9ubxMeta } from "C:/Dev/Git/Groenhart.Webshop.App/groenhart-app/pages/index.vue?macro=true";
import { default as _91id_93XsIinr2qleMeta } from "C:/Dev/Git/Groenhart.Webshop.App/groenhart-app/pages/locations/[id].vue?macro=true";
import { default as indexYoRxtK8K20Meta } from "C:/Dev/Git/Groenhart.Webshop.App/groenhart-app/pages/locations/index.vue?macro=true";
import { default as loging7QBp2e2kmMeta } from "C:/Dev/Git/Groenhart.Webshop.App/groenhart-app/pages/login.vue?macro=true";
import { default as addresses5YYYIiyF2MMeta } from "C:/Dev/Git/Groenhart.Webshop.App/groenhart-app/pages/mijn-groenhart/addresses.vue?macro=true";
import { default as indexGAkp1ycm18Meta } from "C:/Dev/Git/Groenhart.Webshop.App/groenhart-app/pages/mijn-groenhart/index.vue?macro=true";
import { default as indexvfYxZSfAGFMeta } from "C:/Dev/Git/Groenhart.Webshop.App/groenhart-app/pages/mijn-groenhart/invoices/index.vue?macro=true";
import { default as payniM5yM7X0GMeta } from "C:/Dev/Git/Groenhart.Webshop.App/groenhart-app/pages/mijn-groenhart/invoices/pay.vue?macro=true";
import { default as order_45historyDV3XknoLsBMeta } from "C:/Dev/Git/Groenhart.Webshop.App/groenhart-app/pages/mijn-groenhart/order-history.vue?macro=true";
import { default as _91id_93dvUeeQ5q5mMeta } from "C:/Dev/Git/Groenhart.Webshop.App/groenhart-app/pages/mijn-groenhart/orders/[id].vue?macro=true";
import { default as indexSNLkDEDHO3Meta } from "C:/Dev/Git/Groenhart.Webshop.App/groenhart-app/pages/mijn-groenhart/orders/index.vue?macro=true";
import { default as personal_45detailsXM0rZvWx0VMeta } from "C:/Dev/Git/Groenhart.Webshop.App/groenhart-app/pages/mijn-groenhart/personal-details.vue?macro=true";
import { default as projects9pWN2EjiwrMeta } from "C:/Dev/Git/Groenhart.Webshop.App/groenhart-app/pages/mijn-groenhart/projects.vue?macro=true";
import { default as _91name_93bSRPjkjGTxMeta } from "C:/Dev/Git/Groenhart.Webshop.App/groenhart-app/pages/mijn-groenhart/wishlist/[name].vue?macro=true";
import { default as indexor6yaEQSsnMeta } from "C:/Dev/Git/Groenhart.Webshop.App/groenhart-app/pages/mijn-groenhart/wishlist/index.vue?macro=true";
import { default as _91id_93m3sEwRhNYHMeta } from "C:/Dev/Git/Groenhart.Webshop.App/groenhart-app/pages/news/[id].vue?macro=true";
import { default as indexb1wTP7X6hpMeta } from "C:/Dev/Git/Groenhart.Webshop.App/groenhart-app/pages/news/index.vue?macro=true";
import { default as onboardingnO5RvH38jeMeta } from "C:/Dev/Git/Groenhart.Webshop.App/groenhart-app/pages/onboarding.vue?macro=true";
import { default as _91id_93lNIMkyX1kNMeta } from "C:/Dev/Git/Groenhart.Webshop.App/groenhart-app/pages/products/[id].vue?macro=true";
import { default as activateYJ7QQl4qYSMeta } from "C:/Dev/Git/Groenhart.Webshop.App/groenhart-app/pages/register/activate-account/activate.vue?macro=true";
import { default as confirmation1ARILMiCpBMeta } from "C:/Dev/Git/Groenhart.Webshop.App/groenhart-app/pages/register/activate-account/confirmation.vue?macro=true";
import { default as in_45progressCvN2HRJl8nMeta } from "C:/Dev/Git/Groenhart.Webshop.App/groenhart-app/pages/register/activate-account/in-progress.vue?macro=true";
import { default as index1rXByO0KJkMeta } from "C:/Dev/Git/Groenhart.Webshop.App/groenhart-app/pages/register/activate-account/index.vue?macro=true";
import { default as checkoutkd4WZgCh5kMeta } from "C:/Dev/Git/Groenhart.Webshop.App/groenhart-app/pages/register/checkout.vue?macro=true";
import { default as confirmationIIggtAfe6dMeta } from "C:/Dev/Git/Groenhart.Webshop.App/groenhart-app/pages/register/confirmation.vue?macro=true";
import { default as create_45accountZM3GGzCdrEMeta } from "C:/Dev/Git/Groenhart.Webshop.App/groenhart-app/pages/register/create-account.vue?macro=true";
import { default as indexb3x3iglKVgMeta } from "C:/Dev/Git/Groenhart.Webshop.App/groenhart-app/pages/register/index.vue?macro=true";
import { default as _91id_93iGuBNuHQsDMeta } from "C:/Dev/Git/Groenhart.Webshop.App/groenhart-app/pages/sales/[id].vue?macro=true";
import { default as indexCGeqzhiBQ8Meta } from "C:/Dev/Git/Groenhart.Webshop.App/groenhart-app/pages/sales/index.vue?macro=true";
import { default as indexeKXrMG51GnMeta } from "C:/Dev/Git/Groenhart.Webshop.App/groenhart-app/pages/scanner/index.vue?macro=true";
import { default as index0vvFlAIAzLMeta } from "C:/Dev/Git/Groenhart.Webshop.App/groenhart-app/pages/search/index.vue?macro=true";
import { default as products5QGsenaV3IMeta } from "C:/Dev/Git/Groenhart.Webshop.App/groenhart-app/pages/search/products.vue?macro=true";
export default [
  {
    name: _91id_9360g4KkWYonMeta?.name ?? "brand-id",
    path: _91id_9360g4KkWYonMeta?.path ?? "/brand/:id()",
    meta: _91id_9360g4KkWYonMeta || {},
    alias: _91id_9360g4KkWYonMeta?.alias || [],
    redirect: _91id_9360g4KkWYonMeta?.redirect || undefined,
    component: () => import("C:/Dev/Git/Groenhart.Webshop.App/groenhart-app/pages/brand/[id].vue").then(m => m.default || m)
  },
  {
    name: cart4Cclz5iO2CMeta?.name ?? "cart",
    path: cart4Cclz5iO2CMeta?.path ?? "/cart",
    meta: cart4Cclz5iO2CMeta || {},
    alias: cart4Cclz5iO2CMeta?.alias || [],
    redirect: cart4Cclz5iO2CMeta?.redirect || undefined,
    component: () => import("C:/Dev/Git/Groenhart.Webshop.App/groenhart-app/pages/cart.vue").then(m => m.default || m)
  },
  {
    name: _91category_93ROlMKDZQjCMeta?.name ?? "categories-category",
    path: _91category_93ROlMKDZQjCMeta?.path ?? "/categories/:category()",
    meta: _91category_93ROlMKDZQjCMeta || {},
    alias: _91category_93ROlMKDZQjCMeta?.alias || [],
    redirect: _91category_93ROlMKDZQjCMeta?.redirect || undefined,
    component: () => import("C:/Dev/Git/Groenhart.Webshop.App/groenhart-app/pages/categories/[category].vue").then(m => m.default || m)
  },
  {
    name: indexXcmxS4wYHLMeta?.name ?? "categories",
    path: indexXcmxS4wYHLMeta?.path ?? "/categories",
    meta: indexXcmxS4wYHLMeta || {},
    alias: indexXcmxS4wYHLMeta?.alias || [],
    redirect: indexXcmxS4wYHLMeta?.redirect || undefined,
    component: () => import("C:/Dev/Git/Groenhart.Webshop.App/groenhart-app/pages/categories/index.vue").then(m => m.default || m)
  },
  {
    name: _91subcategory_93WmMOvqko3oMeta?.name ?? "categories-products-subcategory",
    path: _91subcategory_93WmMOvqko3oMeta?.path ?? "/categories/products/:subcategory()",
    meta: _91subcategory_93WmMOvqko3oMeta || {},
    alias: _91subcategory_93WmMOvqko3oMeta?.alias || [],
    redirect: _91subcategory_93WmMOvqko3oMeta?.redirect || undefined,
    component: () => import("C:/Dev/Git/Groenhart.Webshop.App/groenhart-app/pages/categories/products/[subcategory].vue").then(m => m.default || m)
  },
  {
    name: confirmationi15Vp1kfspMeta?.name ?? "checkout-confirmation",
    path: confirmationi15Vp1kfspMeta?.path ?? "/checkout/confirmation",
    meta: confirmationi15Vp1kfspMeta || {},
    alias: confirmationi15Vp1kfspMeta?.alias || [],
    redirect: confirmationi15Vp1kfspMeta?.redirect || undefined,
    component: () => import("C:/Dev/Git/Groenhart.Webshop.App/groenhart-app/pages/checkout/confirmation.vue").then(m => m.default || m)
  },
  {
    name: indexm9zHqwuC9aMeta?.name ?? "checkout",
    path: indexm9zHqwuC9aMeta?.path ?? "/checkout",
    meta: indexm9zHqwuC9aMeta || {},
    alias: indexm9zHqwuC9aMeta?.alias || [],
    redirect: indexm9zHqwuC9aMeta?.redirect || undefined,
    component: () => import("C:/Dev/Git/Groenhart.Webshop.App/groenhart-app/pages/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: indexGmAmBjGt0YMeta?.name ?? "forgot-password",
    path: indexGmAmBjGt0YMeta?.path ?? "/forgot-password",
    meta: indexGmAmBjGt0YMeta || {},
    alias: indexGmAmBjGt0YMeta?.alias || [],
    redirect: indexGmAmBjGt0YMeta?.redirect || undefined,
    component: () => import("C:/Dev/Git/Groenhart.Webshop.App/groenhart-app/pages/forgot-password/index.vue").then(m => m.default || m)
  },
  {
    name: reset3UT5sJbtYQMeta?.name ?? "forgot-password-reset",
    path: reset3UT5sJbtYQMeta?.path ?? "/forgot-password/reset",
    meta: reset3UT5sJbtYQMeta || {},
    alias: reset3UT5sJbtYQMeta?.alias || [],
    redirect: reset3UT5sJbtYQMeta?.redirect || undefined,
    component: () => import("C:/Dev/Git/Groenhart.Webshop.App/groenhart-app/pages/forgot-password/reset.vue").then(m => m.default || m)
  },
  {
    name: index6jYm0c9ubxMeta?.name ?? "index",
    path: index6jYm0c9ubxMeta?.path ?? "/",
    meta: index6jYm0c9ubxMeta || {},
    alias: index6jYm0c9ubxMeta?.alias || [],
    redirect: index6jYm0c9ubxMeta?.redirect || undefined,
    component: () => import("C:/Dev/Git/Groenhart.Webshop.App/groenhart-app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93XsIinr2qleMeta?.name ?? "locations-id",
    path: _91id_93XsIinr2qleMeta?.path ?? "/locations/:id()",
    meta: _91id_93XsIinr2qleMeta || {},
    alias: _91id_93XsIinr2qleMeta?.alias || [],
    redirect: _91id_93XsIinr2qleMeta?.redirect || undefined,
    component: () => import("C:/Dev/Git/Groenhart.Webshop.App/groenhart-app/pages/locations/[id].vue").then(m => m.default || m)
  },
  {
    name: indexYoRxtK8K20Meta?.name ?? "locations",
    path: indexYoRxtK8K20Meta?.path ?? "/locations",
    meta: indexYoRxtK8K20Meta || {},
    alias: indexYoRxtK8K20Meta?.alias || [],
    redirect: indexYoRxtK8K20Meta?.redirect || undefined,
    component: () => import("C:/Dev/Git/Groenhart.Webshop.App/groenhart-app/pages/locations/index.vue").then(m => m.default || m)
  },
  {
    name: loging7QBp2e2kmMeta?.name ?? "login",
    path: loging7QBp2e2kmMeta?.path ?? "/login",
    meta: loging7QBp2e2kmMeta || {},
    alias: loging7QBp2e2kmMeta?.alias || [],
    redirect: loging7QBp2e2kmMeta?.redirect || undefined,
    component: () => import("C:/Dev/Git/Groenhart.Webshop.App/groenhart-app/pages/login.vue").then(m => m.default || m)
  },
  {
    name: addresses5YYYIiyF2MMeta?.name ?? "mijn-groenhart-addresses",
    path: addresses5YYYIiyF2MMeta?.path ?? "/mijn-groenhart/addresses",
    meta: addresses5YYYIiyF2MMeta || {},
    alias: addresses5YYYIiyF2MMeta?.alias || [],
    redirect: addresses5YYYIiyF2MMeta?.redirect || undefined,
    component: () => import("C:/Dev/Git/Groenhart.Webshop.App/groenhart-app/pages/mijn-groenhart/addresses.vue").then(m => m.default || m)
  },
  {
    name: indexGAkp1ycm18Meta?.name ?? "mijn-groenhart",
    path: indexGAkp1ycm18Meta?.path ?? "/mijn-groenhart",
    meta: indexGAkp1ycm18Meta || {},
    alias: indexGAkp1ycm18Meta?.alias || [],
    redirect: indexGAkp1ycm18Meta?.redirect || undefined,
    component: () => import("C:/Dev/Git/Groenhart.Webshop.App/groenhart-app/pages/mijn-groenhart/index.vue").then(m => m.default || m)
  },
  {
    name: indexvfYxZSfAGFMeta?.name ?? "mijn-groenhart-invoices",
    path: indexvfYxZSfAGFMeta?.path ?? "/mijn-groenhart/invoices",
    meta: indexvfYxZSfAGFMeta || {},
    alias: indexvfYxZSfAGFMeta?.alias || [],
    redirect: indexvfYxZSfAGFMeta?.redirect || undefined,
    component: () => import("C:/Dev/Git/Groenhart.Webshop.App/groenhart-app/pages/mijn-groenhart/invoices/index.vue").then(m => m.default || m)
  },
  {
    name: payniM5yM7X0GMeta?.name ?? "mijn-groenhart-invoices-pay",
    path: payniM5yM7X0GMeta?.path ?? "/mijn-groenhart/invoices/pay",
    meta: payniM5yM7X0GMeta || {},
    alias: payniM5yM7X0GMeta?.alias || [],
    redirect: payniM5yM7X0GMeta?.redirect || undefined,
    component: () => import("C:/Dev/Git/Groenhart.Webshop.App/groenhart-app/pages/mijn-groenhart/invoices/pay.vue").then(m => m.default || m)
  },
  {
    name: order_45historyDV3XknoLsBMeta?.name ?? "mijn-groenhart-order-history",
    path: order_45historyDV3XknoLsBMeta?.path ?? "/mijn-groenhart/order-history",
    meta: order_45historyDV3XknoLsBMeta || {},
    alias: order_45historyDV3XknoLsBMeta?.alias || [],
    redirect: order_45historyDV3XknoLsBMeta?.redirect || undefined,
    component: () => import("C:/Dev/Git/Groenhart.Webshop.App/groenhart-app/pages/mijn-groenhart/order-history.vue").then(m => m.default || m)
  },
  {
    name: _91id_93dvUeeQ5q5mMeta?.name ?? "mijn-groenhart-orders-id",
    path: _91id_93dvUeeQ5q5mMeta?.path ?? "/mijn-groenhart/orders/:id()",
    meta: _91id_93dvUeeQ5q5mMeta || {},
    alias: _91id_93dvUeeQ5q5mMeta?.alias || [],
    redirect: _91id_93dvUeeQ5q5mMeta?.redirect || undefined,
    component: () => import("C:/Dev/Git/Groenhart.Webshop.App/groenhart-app/pages/mijn-groenhart/orders/[id].vue").then(m => m.default || m)
  },
  {
    name: indexSNLkDEDHO3Meta?.name ?? "mijn-groenhart-orders",
    path: indexSNLkDEDHO3Meta?.path ?? "/mijn-groenhart/orders",
    meta: indexSNLkDEDHO3Meta || {},
    alias: indexSNLkDEDHO3Meta?.alias || [],
    redirect: indexSNLkDEDHO3Meta?.redirect || undefined,
    component: () => import("C:/Dev/Git/Groenhart.Webshop.App/groenhart-app/pages/mijn-groenhart/orders/index.vue").then(m => m.default || m)
  },
  {
    name: personal_45detailsXM0rZvWx0VMeta?.name ?? "mijn-groenhart-personal-details",
    path: personal_45detailsXM0rZvWx0VMeta?.path ?? "/mijn-groenhart/personal-details",
    meta: personal_45detailsXM0rZvWx0VMeta || {},
    alias: personal_45detailsXM0rZvWx0VMeta?.alias || [],
    redirect: personal_45detailsXM0rZvWx0VMeta?.redirect || undefined,
    component: () => import("C:/Dev/Git/Groenhart.Webshop.App/groenhart-app/pages/mijn-groenhart/personal-details.vue").then(m => m.default || m)
  },
  {
    name: projects9pWN2EjiwrMeta?.name ?? "mijn-groenhart-projects",
    path: projects9pWN2EjiwrMeta?.path ?? "/mijn-groenhart/projects",
    meta: projects9pWN2EjiwrMeta || {},
    alias: projects9pWN2EjiwrMeta?.alias || [],
    redirect: projects9pWN2EjiwrMeta?.redirect || undefined,
    component: () => import("C:/Dev/Git/Groenhart.Webshop.App/groenhart-app/pages/mijn-groenhart/projects.vue").then(m => m.default || m)
  },
  {
    name: _91name_93bSRPjkjGTxMeta?.name ?? "mijn-groenhart-wishlist-name",
    path: _91name_93bSRPjkjGTxMeta?.path ?? "/mijn-groenhart/wishlist/:name()",
    meta: _91name_93bSRPjkjGTxMeta || {},
    alias: _91name_93bSRPjkjGTxMeta?.alias || [],
    redirect: _91name_93bSRPjkjGTxMeta?.redirect || undefined,
    component: () => import("C:/Dev/Git/Groenhart.Webshop.App/groenhart-app/pages/mijn-groenhart/wishlist/[name].vue").then(m => m.default || m)
  },
  {
    name: indexor6yaEQSsnMeta?.name ?? "mijn-groenhart-wishlist",
    path: indexor6yaEQSsnMeta?.path ?? "/mijn-groenhart/wishlist",
    meta: indexor6yaEQSsnMeta || {},
    alias: indexor6yaEQSsnMeta?.alias || [],
    redirect: indexor6yaEQSsnMeta?.redirect || undefined,
    component: () => import("C:/Dev/Git/Groenhart.Webshop.App/groenhart-app/pages/mijn-groenhart/wishlist/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93m3sEwRhNYHMeta?.name ?? "news-id",
    path: _91id_93m3sEwRhNYHMeta?.path ?? "/news/:id()",
    meta: _91id_93m3sEwRhNYHMeta || {},
    alias: _91id_93m3sEwRhNYHMeta?.alias || [],
    redirect: _91id_93m3sEwRhNYHMeta?.redirect || undefined,
    component: () => import("C:/Dev/Git/Groenhart.Webshop.App/groenhart-app/pages/news/[id].vue").then(m => m.default || m)
  },
  {
    name: indexb1wTP7X6hpMeta?.name ?? "news",
    path: indexb1wTP7X6hpMeta?.path ?? "/news",
    meta: indexb1wTP7X6hpMeta || {},
    alias: indexb1wTP7X6hpMeta?.alias || [],
    redirect: indexb1wTP7X6hpMeta?.redirect || undefined,
    component: () => import("C:/Dev/Git/Groenhart.Webshop.App/groenhart-app/pages/news/index.vue").then(m => m.default || m)
  },
  {
    name: onboardingnO5RvH38jeMeta?.name ?? "onboarding",
    path: onboardingnO5RvH38jeMeta?.path ?? "/onboarding",
    meta: onboardingnO5RvH38jeMeta || {},
    alias: onboardingnO5RvH38jeMeta?.alias || [],
    redirect: onboardingnO5RvH38jeMeta?.redirect || undefined,
    component: () => import("C:/Dev/Git/Groenhart.Webshop.App/groenhart-app/pages/onboarding.vue").then(m => m.default || m)
  },
  {
    name: _91id_93lNIMkyX1kNMeta?.name ?? "products-id",
    path: _91id_93lNIMkyX1kNMeta?.path ?? "/products/:id()",
    meta: _91id_93lNIMkyX1kNMeta || {},
    alias: _91id_93lNIMkyX1kNMeta?.alias || [],
    redirect: _91id_93lNIMkyX1kNMeta?.redirect || undefined,
    component: () => import("C:/Dev/Git/Groenhart.Webshop.App/groenhart-app/pages/products/[id].vue").then(m => m.default || m)
  },
  {
    name: activateYJ7QQl4qYSMeta?.name ?? "register-activate-account-activate",
    path: activateYJ7QQl4qYSMeta?.path ?? "/register/activate-account/activate",
    meta: activateYJ7QQl4qYSMeta || {},
    alias: activateYJ7QQl4qYSMeta?.alias || [],
    redirect: activateYJ7QQl4qYSMeta?.redirect || undefined,
    component: () => import("C:/Dev/Git/Groenhart.Webshop.App/groenhart-app/pages/register/activate-account/activate.vue").then(m => m.default || m)
  },
  {
    name: confirmation1ARILMiCpBMeta?.name ?? "register-activate-account-confirmation",
    path: confirmation1ARILMiCpBMeta?.path ?? "/register/activate-account/confirmation",
    meta: confirmation1ARILMiCpBMeta || {},
    alias: confirmation1ARILMiCpBMeta?.alias || [],
    redirect: confirmation1ARILMiCpBMeta?.redirect || undefined,
    component: () => import("C:/Dev/Git/Groenhart.Webshop.App/groenhart-app/pages/register/activate-account/confirmation.vue").then(m => m.default || m)
  },
  {
    name: in_45progressCvN2HRJl8nMeta?.name ?? "register-activate-account-in-progress",
    path: in_45progressCvN2HRJl8nMeta?.path ?? "/register/activate-account/in-progress",
    meta: in_45progressCvN2HRJl8nMeta || {},
    alias: in_45progressCvN2HRJl8nMeta?.alias || [],
    redirect: in_45progressCvN2HRJl8nMeta?.redirect || undefined,
    component: () => import("C:/Dev/Git/Groenhart.Webshop.App/groenhart-app/pages/register/activate-account/in-progress.vue").then(m => m.default || m)
  },
  {
    name: index1rXByO0KJkMeta?.name ?? "register-activate-account",
    path: index1rXByO0KJkMeta?.path ?? "/register/activate-account",
    meta: index1rXByO0KJkMeta || {},
    alias: index1rXByO0KJkMeta?.alias || [],
    redirect: index1rXByO0KJkMeta?.redirect || undefined,
    component: () => import("C:/Dev/Git/Groenhart.Webshop.App/groenhart-app/pages/register/activate-account/index.vue").then(m => m.default || m)
  },
  {
    name: checkoutkd4WZgCh5kMeta?.name ?? "register-checkout",
    path: checkoutkd4WZgCh5kMeta?.path ?? "/register/checkout",
    meta: checkoutkd4WZgCh5kMeta || {},
    alias: checkoutkd4WZgCh5kMeta?.alias || [],
    redirect: checkoutkd4WZgCh5kMeta?.redirect || undefined,
    component: () => import("C:/Dev/Git/Groenhart.Webshop.App/groenhart-app/pages/register/checkout.vue").then(m => m.default || m)
  },
  {
    name: confirmationIIggtAfe6dMeta?.name ?? "register-confirmation",
    path: confirmationIIggtAfe6dMeta?.path ?? "/register/confirmation",
    meta: confirmationIIggtAfe6dMeta || {},
    alias: confirmationIIggtAfe6dMeta?.alias || [],
    redirect: confirmationIIggtAfe6dMeta?.redirect || undefined,
    component: () => import("C:/Dev/Git/Groenhart.Webshop.App/groenhart-app/pages/register/confirmation.vue").then(m => m.default || m)
  },
  {
    name: create_45accountZM3GGzCdrEMeta?.name ?? "register-create-account",
    path: create_45accountZM3GGzCdrEMeta?.path ?? "/register/create-account",
    meta: create_45accountZM3GGzCdrEMeta || {},
    alias: create_45accountZM3GGzCdrEMeta?.alias || [],
    redirect: create_45accountZM3GGzCdrEMeta?.redirect || undefined,
    component: () => import("C:/Dev/Git/Groenhart.Webshop.App/groenhart-app/pages/register/create-account.vue").then(m => m.default || m)
  },
  {
    name: indexb3x3iglKVgMeta?.name ?? "register",
    path: indexb3x3iglKVgMeta?.path ?? "/register",
    meta: indexb3x3iglKVgMeta || {},
    alias: indexb3x3iglKVgMeta?.alias || [],
    redirect: indexb3x3iglKVgMeta?.redirect || undefined,
    component: () => import("C:/Dev/Git/Groenhart.Webshop.App/groenhart-app/pages/register/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93iGuBNuHQsDMeta?.name ?? "sales-id",
    path: _91id_93iGuBNuHQsDMeta?.path ?? "/sales/:id()",
    meta: _91id_93iGuBNuHQsDMeta || {},
    alias: _91id_93iGuBNuHQsDMeta?.alias || [],
    redirect: _91id_93iGuBNuHQsDMeta?.redirect || undefined,
    component: () => import("C:/Dev/Git/Groenhart.Webshop.App/groenhart-app/pages/sales/[id].vue").then(m => m.default || m)
  },
  {
    name: indexCGeqzhiBQ8Meta?.name ?? "sales",
    path: indexCGeqzhiBQ8Meta?.path ?? "/sales",
    meta: indexCGeqzhiBQ8Meta || {},
    alias: indexCGeqzhiBQ8Meta?.alias || [],
    redirect: indexCGeqzhiBQ8Meta?.redirect || undefined,
    component: () => import("C:/Dev/Git/Groenhart.Webshop.App/groenhart-app/pages/sales/index.vue").then(m => m.default || m)
  },
  {
    name: indexeKXrMG51GnMeta?.name ?? "scanner",
    path: indexeKXrMG51GnMeta?.path ?? "/scanner",
    meta: indexeKXrMG51GnMeta || {},
    alias: indexeKXrMG51GnMeta?.alias || [],
    redirect: indexeKXrMG51GnMeta?.redirect || undefined,
    component: () => import("C:/Dev/Git/Groenhart.Webshop.App/groenhart-app/pages/scanner/index.vue").then(m => m.default || m)
  },
  {
    name: index0vvFlAIAzLMeta?.name ?? "search",
    path: index0vvFlAIAzLMeta?.path ?? "/search",
    meta: index0vvFlAIAzLMeta || {},
    alias: index0vvFlAIAzLMeta?.alias || [],
    redirect: index0vvFlAIAzLMeta?.redirect || undefined,
    component: () => import("C:/Dev/Git/Groenhart.Webshop.App/groenhart-app/pages/search/index.vue").then(m => m.default || m)
  },
  {
    name: products5QGsenaV3IMeta?.name ?? "search-products",
    path: products5QGsenaV3IMeta?.path ?? "/search/products",
    meta: products5QGsenaV3IMeta || {},
    alias: products5QGsenaV3IMeta?.alias || [],
    redirect: products5QGsenaV3IMeta?.redirect || undefined,
    component: () => import("C:/Dev/Git/Groenhart.Webshop.App/groenhart-app/pages/search/products.vue").then(m => m.default || m)
  }
]