/* tslint:disable */
/* eslint-disable */
/**
 * Groenhart API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0.30.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CredentialsApiModel } from './CredentialsApiModel';
import {
    CredentialsApiModelFromJSON,
    CredentialsApiModelFromJSONTyped,
    CredentialsApiModelToJSON,
} from './CredentialsApiModel';
import type { InvoiceAddressApiModel } from './InvoiceAddressApiModel';
import {
    InvoiceAddressApiModelFromJSON,
    InvoiceAddressApiModelFromJSONTyped,
    InvoiceAddressApiModelToJSON,
} from './InvoiceAddressApiModel';
import type { InvoicingApiModel } from './InvoicingApiModel';
import {
    InvoicingApiModelFromJSON,
    InvoicingApiModelFromJSONTyped,
    InvoicingApiModelToJSON,
} from './InvoicingApiModel';
import type { RegisterPersonalDetailsApiModel } from './RegisterPersonalDetailsApiModel';
import {
    RegisterPersonalDetailsApiModelFromJSON,
    RegisterPersonalDetailsApiModelFromJSONTyped,
    RegisterPersonalDetailsApiModelToJSON,
} from './RegisterPersonalDetailsApiModel';

/**
 * 
 * @export
 * @interface RegisterRequestModel
 */
export interface RegisterRequestModel {
    /**
     * 
     * @type {RegisterPersonalDetailsApiModel}
     * @memberof RegisterRequestModel
     */
    personalDetails?: RegisterPersonalDetailsApiModel;
    /**
     * 
     * @type {CredentialsApiModel}
     * @memberof RegisterRequestModel
     */
    credentials?: CredentialsApiModel;
    /**
     * 
     * @type {InvoiceAddressApiModel}
     * @memberof RegisterRequestModel
     */
    invoiceAddress?: InvoiceAddressApiModel;
    /**
     * 
     * @type {InvoicingApiModel}
     * @memberof RegisterRequestModel
     */
    invoicing?: InvoicingApiModel;
    /**
     * 
     * @type {boolean}
     * @memberof RegisterRequestModel
     */
    acceptTerms?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof RegisterRequestModel
     */
    newsletter?: boolean | null;
}

/**
 * Check if a given object implements the RegisterRequestModel interface.
 */
export function instanceOfRegisterRequestModel(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function RegisterRequestModelFromJSON(json: any): RegisterRequestModel {
    return RegisterRequestModelFromJSONTyped(json, false);
}

export function RegisterRequestModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): RegisterRequestModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'personalDetails': !exists(json, 'personalDetails') ? undefined : RegisterPersonalDetailsApiModelFromJSON(json['personalDetails']),
        'credentials': !exists(json, 'credentials') ? undefined : CredentialsApiModelFromJSON(json['credentials']),
        'invoiceAddress': !exists(json, 'invoiceAddress') ? undefined : InvoiceAddressApiModelFromJSON(json['invoiceAddress']),
        'invoicing': !exists(json, 'invoicing') ? undefined : InvoicingApiModelFromJSON(json['invoicing']),
        'acceptTerms': !exists(json, 'acceptTerms') ? undefined : json['acceptTerms'],
        'newsletter': !exists(json, 'newsletter') ? undefined : json['newsletter'],
    };
}

export function RegisterRequestModelToJSON(value?: RegisterRequestModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'personalDetails': RegisterPersonalDetailsApiModelToJSON(value.personalDetails),
        'credentials': CredentialsApiModelToJSON(value.credentials),
        'invoiceAddress': InvoiceAddressApiModelToJSON(value.invoiceAddress),
        'invoicing': InvoicingApiModelToJSON(value.invoicing),
        'acceptTerms': value.acceptTerms,
        'newsletter': value.newsletter,
    };
}

