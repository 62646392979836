export default defineNuxtRouteMiddleware(() => {
  const { status } = useAuth();
  const gtm = useGtm();

  if (gtm) {
    if (status.value === 'authenticated') {
      const { member } = useGeneralStore();

      gtm.push({
        ingelogd: true,
        type_klant: member?.isBusiness ? 'bedrijf' : 'particulier',
        user_id: member?.id,
      });
    } else {
      gtm.push({
        ingelogd: false,
        type_klant: undefined,
        user_id: undefined,
      });
    }
  }
});
