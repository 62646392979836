export function useApiHeaders() {
  const { data } = useAuth();

  const headers: HeadersInit = {};

  if (data.value?.accessToken) {
    headers.Authorization = `Bearer ${data.value.accessToken}`;
  }

  headers['Content-Type'] = 'application/json';

  return headers;
}
