/* tslint:disable */
/* eslint-disable */
/**
 * Groenhart API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0.30.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AddVoucherCodeResponseModelResult } from './AddVoucherCodeResponseModelResult';
import {
    AddVoucherCodeResponseModelResultFromJSON,
    AddVoucherCodeResponseModelResultFromJSONTyped,
    AddVoucherCodeResponseModelResultToJSON,
} from './AddVoucherCodeResponseModelResult';
import type { BasketApiModel } from './BasketApiModel';
import {
    BasketApiModelFromJSON,
    BasketApiModelFromJSONTyped,
    BasketApiModelToJSON,
} from './BasketApiModel';

/**
 * 
 * @export
 * @interface AddVoucherCodeResponseModel
 */
export interface AddVoucherCodeResponseModel {
    /**
     * 
     * @type {AddVoucherCodeResponseModelResult}
     * @memberof AddVoucherCodeResponseModel
     */
    result?: AddVoucherCodeResponseModelResult;
    /**
     * 
     * @type {string}
     * @memberof AddVoucherCodeResponseModel
     */
    message?: string | null;
    /**
     * 
     * @type {BasketApiModel}
     * @memberof AddVoucherCodeResponseModel
     */
    basket?: BasketApiModel;
}

/**
 * Check if a given object implements the AddVoucherCodeResponseModel interface.
 */
export function instanceOfAddVoucherCodeResponseModel(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function AddVoucherCodeResponseModelFromJSON(json: any): AddVoucherCodeResponseModel {
    return AddVoucherCodeResponseModelFromJSONTyped(json, false);
}

export function AddVoucherCodeResponseModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): AddVoucherCodeResponseModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'result': !exists(json, 'result') ? undefined : AddVoucherCodeResponseModelResultFromJSON(json['result']),
        'message': !exists(json, 'message') ? undefined : json['message'],
        'basket': !exists(json, 'basket') ? undefined : BasketApiModelFromJSON(json['basket']),
    };
}

export function AddVoucherCodeResponseModelToJSON(value?: AddVoucherCodeResponseModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'result': AddVoucherCodeResponseModelResultToJSON(value.result),
        'message': value.message,
        'basket': BasketApiModelToJSON(value.basket),
    };
}

