import { storeToRefs } from 'pinia';

export default defineNuxtRouteMiddleware((to) => {
  if (process.client) {
    return;
  }

  const { status } = useAuth();

  if (status.value === 'authenticated') {
    return;
  }

  const generalStore = useGeneralStore();
  const { firstTimeVisit } = storeToRefs(generalStore);

  if (to.path === routes.onboarding.path) {
    return;
  }

  if (firstTimeVisit.value) {
    firstTimeVisit.value = false;
    return navigateTo({ path: routes.onboarding.path });
  }
});
