<template>
  <div class="loading">
    <LottieAnimation animation-link="/lottie/loading.json" />
  </div>
</template>

<style lang="scss" scoped>
.loading {
  overflow: hidden;
  height: 100vh;
  width: 100%;
  background-color: $c-white;
  position: fixed;
  inset: 0;
  z-index: 9999;
}
</style>
