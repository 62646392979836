import { defineStore } from 'pinia';
import type {
  BasketApiModel,
  BasketsBasketIdShipmentPutRequest,
  OrderLineApiModel,
} from '@groenhart/api';

export const useBasketStore = defineStore('basket-store', () => {
  const {
    getBasketById,
    initBasket,
    addToBasket: add,
    deleteFromBasket: deleteOrderLine,
    updateOrderLine: updateQuantity,
    addVoucherCode,
    deleteVoucherCode,
    updateShipment,
    deleteOrderLinesFromBasket,
    getMemberBasket,
    getCorrectBasketAfterLogin,
    executeBasketPipeline,
  } = useBasketApi();

  const { $toast } = useNuxtApp();
  const { gtmErrorEvent } = useGtmEvents();
  const contentStore = useContentStore();
  const { status } = useAuth();

  const basket = ref<BasketApiModel | null>(null);
  // Use expires so that the cookie is not deleted when the browser is closed
  const basketIdCookie = useCookie<string>('basket_id', {
    expires: new Date('3000/01/01'),
  });

  const hasBasket = computed(() => !!basket.value?.id);
  const count = computed(() => basket.value?.orderLines?.length ?? 0);
  const totalProductsCount = computed(() => {
    return (
      basket.value?.orderLines?.reduce((acc, orderLine) => {
        return acc + (orderLine.quantity ?? 0);
      }, 0) ?? 0
    );
  });

  const basketId = computed(() => basketIdCookie.value || basket.value?.id || '');

  async function setBasket() {
    const basketResponse = await initBasket();
    if (basketResponse) {
      basketIdCookie.value = basketResponse.id ?? '';
      basket.value = basketResponse;
    }
  }

  async function getBasket() {
    const basketResponse =
      status.value === 'authenticated'
        ? await getCorrectBasketAfterLogin({ appBasketId: basketIdCookie.value })
        : await getBasketById(basketId.value);
    if (basketResponse.raw.status === 200) {
      const newBasket = await basketResponse.value();
      basket.value = newBasket;
      if (newBasket.id) {
        basketIdCookie.value = newBasket.id;
      }
    } else if (basketResponse.raw.status === 204) {
      setBasket();
    }
  }

  async function deleteOrderLines() {
    const basketResponse = await deleteOrderLinesFromBasket({
      basketId: basketId.value,
    });
    if (basketResponse) {
      basket.value = basketResponse;
    }
  }

  async function createOrGetBasket() {
    if (status.value === 'authenticated') {
      if (!basketIdCookie.value) {
        const memberBasketResponse = await getMemberBasket();
        if (memberBasketResponse.raw.status === 200) {
          const newBasket = await memberBasketResponse.value();
          basket.value = newBasket;
          basketIdCookie.value = newBasket.id ?? '';
        }
      } else {
        await getBasket();
      }
    } else if (basketIdCookie.value) {
      await getBasket();
    } else {
      await setBasket();
    }
  }

  async function addToBasket({
    quantity,
    sku,
  }: {
    quantity: OrderLineApiModel['quantity'];
    sku: OrderLineApiModel['sku'];
  }) {
    if (hasBasket.value) {
      try {
        const basketResponse = await add({
          basketId: basketId.value,
          quantity,
          sku,
        });

        if (basketResponse?.success && basketResponse.basket) {
          basket.value = await basketResponse.basket;
        }

        return basketResponse?.basket;
      } catch (error: any) {
        const res = await error.response.json();
        return res;
      }
    }
  }

  async function deleteFromBasket({ orderLine }: { orderLine: OrderLineApiModel }) {
    if (hasBasket.value) {
      const basketResponse = await deleteOrderLine({
        basketId: basketId.value,
        orderLineId: orderLine.id,
      });
      if (basketResponse?.raw.status === 200) {
        $toast({
          type: 'ok',
          alertText: contentStore.content?.productRemovedBasket ?? '',
          duration: NOTIFICATION_DURATION.SUCCESS,
        });

        basket.value = await basketResponse.value();

        const gtm = useGtm();

        gtm?.push({
          event: 'remove_from_cart',
          ecommerce: {
            currency: 'EUR',
            value: orderLine.priceExclVat,
            items: [
              {
                item_id: orderLine.sku,
                item_name: orderLine.name,
                price: (orderLine.priceExclVat ?? 0) / (orderLine.unitQuantity ?? 1),
                quantity: orderLine.quantity,
                item_brand: orderLine.brandName,
                item_category: orderLine.mainCategory,
              },
            ],
          },
        });
      } else {
        $toast({
          type: 'warn',
          alertText: contentStore.content?.somethingWentWrong ?? '',
          duration: NOTIFICATION_DURATION.WARN,
        });
      }
    }
  }

  async function updateOrderLine({
    orderLineId,
    newQuantity,
  }: {
    orderLineId: OrderLineApiModel['id'];
    newQuantity: OrderLineApiModel['quantity'];
  }) {
    if (hasBasket.value) {
      try {
        const basketResponse = await updateQuantity({
          basketId: basketId.value,
          orderLineId,
          newQuantity,
        });

        if (basketResponse?.success && basketResponse.basket) {
          basket.value = await basketResponse.basket;
        }

        return basketResponse?.basket;
      } catch (error: any) {
        const res = await error.response.json();
        return res;
      }
    }
  }

  async function addVoucherToBasket({
    basketId,
    code,
  }: {
    basketId: BasketApiModel['id'];
    code: string;
  }) {
    if (hasBasket.value) {
      const response = await addVoucherCode({
        basketId,
        code,
      });

      if (response?.result === 'Success' && response.basket) {
        basket.value = response.basket;
      } else if (response?.result === 'Warning') {
        if (response.basket) {
          basket.value = response.basket;
        }

        $toast({
          type: 'warn',
          alertText: response.message ?? contentStore.content?.somethingWentWrong ?? '',
          duration: NOTIFICATION_DURATION.WARN,
        });
      } else if (response?.result === 'Error') {
        $toast({
          type: 'error',
          alertText: response.message ?? contentStore.content?.somethingWentWrong ?? '',
          duration: NOTIFICATION_DURATION.ERROR,
        });

        gtmErrorEvent(
          response.message ?? contentStore.content?.somethingWentWrong ?? '',
          'Add voucher to basket'
        );
      }
    }
  }

  async function deleteVoucherFromBasket({
    basketId,
    code,
  }: {
    basketId: BasketApiModel['id'];
    code: string;
  }) {
    if (hasBasket.value) {
      const response = await deleteVoucherCode({
        basketId,
        code,
      });

      if (response?.success && response.basket) {
        basket.value = response.basket;
      }
    }
  }

  async function updateDeliveryMethod({
    basketId,
    countryId,
    shippingMethodId,
  }: BasketsBasketIdShipmentPutRequest) {
    const response = await updateShipment({
      basketId,
      countryId,
      shippingMethodId,
    });

    if (response) {
      basket.value = response;
    }
  }

  async function execBasketPipeline() {
    const isAuthenticated = status.value === 'authenticated';

    const basket = isAuthenticated ? basketIdCookie.value : basketId.value;

    if (basket) {
      await executeBasketPipeline({
        basketId: basket,
      });
    }
  }

  return {
    basket,
    basketIdCookie,
    getBasket,
    setBasket,
    hasBasket,
    createOrGetBasket,
    count,
    totalProductsCount,
    addToBasket,
    deleteFromBasket,
    updateOrderLine,
    addVoucherToBasket,
    deleteVoucherFromBasket,
    updateDeliveryMethod,
    deleteOrderLines,
    execBasketPipeline,
  };
});
