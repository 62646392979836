import { DefaultConfig, LocationsApi } from '@groenhart/api';
import { ApiBase } from './base.api';

export function useLocationsApi() {
  const runtimeConfig = useRuntimeConfig();
  const headers = useApiHeaders();

  const apiBase = new ApiBase(runtimeConfig.public.apiBase);
  DefaultConfig.config = apiBase.getDefaultConfig();

  const locationsApi = new LocationsApi();

  async function getAllLocations() {
    return await locationsApi.locationsGet({ headers });
  }

  async function getLocationById(id: string) {
    return await locationsApi.locationsIdGet({ id }, { headers });
  }

  async function getLocationCardById(id: string) {
    return await locationsApi.locationsIdCardGet({ id }, { headers });
  }

  async function getFavoriteLocation() {
    return await locationsApi.locationsFavoriteGetRaw({ headers });
  }

  async function changeFavoriteLocation(id: string) {
    return await locationsApi.locationsFavoritePut({ id }, { headers });
  }

  async function removeFavoriteLocation() {
    return await locationsApi.locationsFavoriteDelete({ headers });
  }

  return {
    getAllLocations,
    getLocationById,
    getLocationCardById,
    getFavoriteLocation,
    changeFavoriteLocation,
    removeFavoriteLocation,
  };
}
