<!-- eslint-disable vue/no-multiple-template-root -->
<template>
  <div class="app-wrapper">
    <Loading v-show="loading" />
    <LazyAlertsAlert v-if="alertStore.isOpen" v-bind="alertStore.props" />
    <NuxtLayout>
      <div class="content-wrapper">
        <NuxtPage />
      </div>
    </NuxtLayout>
  </div>
</template>

<script lang="ts" setup>
import { storeToRefs } from 'pinia';
import type { Icon } from '@/types/icon';
import { initializeNative } from '~/native/native.api';

const { createOrGetBasket, execBasketPipeline } = useBasketStore();
const alertStore = useAlertStore();

await execBasketPipeline();
await useAsyncData('basket', () => createOrGetBasket());
const { getContent, getUsps } = useContentApi();
const { getSubsription } = useNewsletterApi();
const { getMembersAccount } = useRegisterApi();
const generalStore = useGeneralStore();
const { loading } = storeToRefs(generalStore);
const { status } = useAuth();
const { data } = await useAsyncData('content', () => getContent());
const { data: uspsData } = await useAsyncData('usps', () => getUsps());

const favoriteLocationStore = useFavoriteLocationStore();

await useAsyncData(() => favoriteLocationStore.initFavoriteLocation());

useWebshopRedirectFn();

generalStore.usps =
  uspsData.value?.map((usp) => ({
    icon: (usp.icon as Icon) ?? 'truck',
    text: usp.title ?? '',
  })) ?? [];

const contentStore = useContentStore();
contentStore.content = data.value;

initializeNative();

onMounted(async () => {
  if (status.value === 'authenticated') {
    const newsletterResponse = await getSubsription();
    generalStore.isNewsletterSubscribed = (newsletterResponse as unknown) === 'true';

    const { data: member } = await useAsyncData('member', () => getMembersAccount());
    generalStore.member = member.value ?? null;
  }
});
</script>

<style lang="scss" scoped>
.app-wrapper {
  height: 100vh;
  display: flex;
  flex-direction: column;
}
</style>
