<template>
  <component :is="iconComponent" v-if="!!iconComponent" />
</template>

<script setup lang="ts">
import { isIcon } from '@/types/icon';
import type { Icon } from '@/types/icon';

const props = defineProps<{
  icon: Icon;
}>();

const iconComponent = computed(() => {
  if (isIcon(props.icon)) {
    return defineAsyncComponent(() => import(`../../assets/icons/${props.icon}.svg`));
  }
  return null;
});
</script>
