import { Configuration } from '@groenhart/api';
import { enableMaintenanceMode } from '~/native/native.api';

export class ApiBase {
  private baseUrl: string;

  constructor(baseUrl: string) {
    this.baseUrl = baseUrl;
  }

  public getDefaultConfig() {
    return new Configuration({
      basePath: this.baseUrl,
      middleware: [
        {
          async post(context) {
            if (context.response.status === 503) {
              enableMaintenanceMode();
            }
          },
        },
      ],
    });
  }
}
