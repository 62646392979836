/* tslint:disable */
/* eslint-disable */
/**
 * Groenhart API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0.30.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UspApiModel
 */
export interface UspApiModel {
    /**
     * 
     * @type {string}
     * @memberof UspApiModel
     */
    title?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UspApiModel
     */
    icon?: string | null;
}

/**
 * Check if a given object implements the UspApiModel interface.
 */
export function instanceOfUspApiModel(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function UspApiModelFromJSON(json: any): UspApiModel {
    return UspApiModelFromJSONTyped(json, false);
}

export function UspApiModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): UspApiModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'title': !exists(json, 'title') ? undefined : json['title'],
        'icon': !exists(json, 'icon') ? undefined : json['icon'],
    };
}

export function UspApiModelToJSON(value?: UspApiModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'title': value.title,
        'icon': value.icon,
    };
}

