import { DefaultConfig, NewsletterApi } from '@groenhart/api';
import { ApiBase } from './base.api';

export function useNewsletterApi() {
  const runtimeConfig = useRuntimeConfig();
  const headers = useApiHeaders();

  const apiBase = new ApiBase(runtimeConfig.public.apiBase);
  DefaultConfig.config = apiBase.getDefaultConfig();

  const newsletterApi = new NewsletterApi();

  async function getSubsription() {
    return await newsletterApi.newsletterGet({ headers });
  }

  async function toggleSubscription(enabled: boolean) {
    return await newsletterApi.newsletterPut({ enabled }, { headers });
  }

  async function subscribeToNewsletter(email: string) {
    return await newsletterApi.newsletterSubscribePutRaw({ email }, { headers });
  }

  return {
    getSubsription,
    toggleSubscription,
    subscribeToNewsletter,
  };
}
