import { defineStore } from 'pinia';
import type { LocationCardApiModel } from '@groenhart/api';

export const useFavoriteLocationStore = defineStore('favorite-location-store', () => {
  const { getFavoriteLocation, getLocationCardById } = useLocationsApi();

  // Use expires so that the cookie is not deleted when the browser is closed
  const favoriteLocationId = useCookie<string>('favorite-location', {
    expires: new Date('3000/01/01'),
  });

  const favoriteLocation = ref<LocationCardApiModel | null>(null);

  async function initFavoriteLocation() {
    const { status } = useAuth();

    try {
      if (status.value !== 'authenticated') {
        if (favoriteLocationId.value) {
          favoriteLocation.value = await getLocationCardById(favoriteLocationId.value);
        }
      } else {
        const response = await getFavoriteLocation();
        const value = await response.value();

        if (value.useCookie) {
          if (favoriteLocationId.value) {
            favoriteLocation.value = await getLocationCardById(favoriteLocationId.value);
          }
        } else {
          favoriteLocation.value = value.location || null;
        }
      }
    } catch (e: any) {
      console.log(e);
    }
  }

  watch(favoriteLocationId, async (newVal) => {
    favoriteLocation.value = newVal ? await getLocationCardById(newVal) : null;
  });

  return {
    favoriteLocation,
    favoriteLocationId,
    initFavoriteLocation,
  };
});
