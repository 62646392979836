/* tslint:disable */
/* eslint-disable */
/**
 * Groenhart API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0.30.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MemberBaseApiModel
 */
export interface MemberBaseApiModel {
    /**
     * 
     * @type {string}
     * @memberof MemberBaseApiModel
     */
    id?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MemberBaseApiModel
     */
    memberId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof MemberBaseApiModel
     */
    fullName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MemberBaseApiModel
     */
    firstName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MemberBaseApiModel
     */
    lastName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MemberBaseApiModel
     */
    emailAddress?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MemberBaseApiModel
     */
    debtorNumber?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof MemberBaseApiModel
     */
    freeShipping?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof MemberBaseApiModel
     */
    defaultAffiliate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MemberBaseApiModel
     */
    mainBranche?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof MemberBaseApiModel
     */
    newsletter?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof MemberBaseApiModel
     */
    isSubAccount?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof MemberBaseApiModel
     */
    isBusiness?: boolean | null;
}

/**
 * Check if a given object implements the MemberBaseApiModel interface.
 */
export function instanceOfMemberBaseApiModel(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function MemberBaseApiModelFromJSON(json: any): MemberBaseApiModel {
    return MemberBaseApiModelFromJSONTyped(json, false);
}

export function MemberBaseApiModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): MemberBaseApiModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'memberId': !exists(json, 'memberId') ? undefined : json['memberId'],
        'fullName': !exists(json, 'fullName') ? undefined : json['fullName'],
        'firstName': !exists(json, 'firstName') ? undefined : json['firstName'],
        'lastName': !exists(json, 'lastName') ? undefined : json['lastName'],
        'emailAddress': !exists(json, 'emailAddress') ? undefined : json['emailAddress'],
        'debtorNumber': !exists(json, 'debtorNumber') ? undefined : json['debtorNumber'],
        'freeShipping': !exists(json, 'freeShipping') ? undefined : json['freeShipping'],
        'defaultAffiliate': !exists(json, 'defaultAffiliate') ? undefined : json['defaultAffiliate'],
        'mainBranche': !exists(json, 'mainBranche') ? undefined : json['mainBranche'],
        'newsletter': !exists(json, 'newsletter') ? undefined : json['newsletter'],
        'isSubAccount': !exists(json, 'isSubAccount') ? undefined : json['isSubAccount'],
        'isBusiness': !exists(json, 'isBusiness') ? undefined : json['isBusiness'],
    };
}

export function MemberBaseApiModelToJSON(value?: MemberBaseApiModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'memberId': value.memberId,
        'fullName': value.fullName,
        'firstName': value.firstName,
        'lastName': value.lastName,
        'emailAddress': value.emailAddress,
        'debtorNumber': value.debtorNumber,
        'freeShipping': value.freeShipping,
        'defaultAffiliate': value.defaultAffiliate,
        'mainBranche': value.mainBranche,
        'newsletter': value.newsletter,
        'isSubAccount': value.isSubAccount,
        'isBusiness': value.isBusiness,
    };
}

