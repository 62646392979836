import { defineStore } from 'pinia';
import type { AlertProps } from '@/components/alerts/Alert.vue';

export const useAlertStore = defineStore('alert-store', () => {
  const isOpen = ref(false);
  const props = ref<AlertProps>({
    alertText: '',
    link: '',
    duration: 0,
  });

  return {
    isOpen,
    props,
  };
});
