<template>
  <component
    :is="htmlTag"
    class="btn"
    :class="[`btn--${variant}`, `btn--${color}`, { 'btn--uppercase': isUpperCase }]">
    <ImagesSvg v-if="icon && iconPosition === 'left'" width="20" height="20" :icon="icon" />
    <slot></slot>
    <ImagesSvg v-if="icon && iconPosition === 'right'" width="20" height="20" :icon="icon" />
  </component>
</template>

<script lang="ts" setup>
import type { Icon } from '@/types/icon';
import type { Color } from '@/types/color';

type ButtonVariant = 'solid' | 'outline';

type IconPosition = 'left' | 'right';

interface ButtonProps {
  asLink?: boolean;
  icon?: Icon;
  variant?: ButtonVariant;
  color?: Color;
  isUpperCase?: boolean;
  iconPosition?: IconPosition;
}

const props = withDefaults(defineProps<ButtonProps>(), {
  icon: undefined,
  isUpperCase: false,
  iconPosition: 'right',
  variant: 'solid',
  color: 'orange',
});

const htmlTag = computed(() => (props.asLink ? resolveComponent('NuxtLink') : 'button'));
</script>

<style lang="scss" scoped>
$this: '.btn';

#{$this} {
  @include button;

  &--outline {
    @include button-outline;
  }

  &--solid {
    @include button-solid;
  }

  &--uppercase {
    text-transform: uppercase;
  }
}
</style>
