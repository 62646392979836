export default function useWebshopRedirectFn() {
  const runtimeConfig = useRuntimeConfig();

  onMounted(() => {
    const aElements = document.querySelectorAll('a');
    aElements.forEach((a) => {
      a.addEventListener('click', (event) => {
        if (a.href.includes(runtimeConfig.public.webshopUrl)) {
          event.preventDefault();
          a.href += '?redirectFromApp';
          window.location.href = a.href;
        }
      });
    });
  });
}
