import {
  RegisterApi,
  MembersApi,
  type RegisterRequestModel,
  type ActivateAccountApiRequestModel,
  type MembersMeDeleteRequest,
  type MembersValidateEmailAddressGetRequest,
  DefaultConfig,
} from '@groenhart/api';
import { ApiBase } from './base.api';

export function useRegisterApi() {
  const runtimeConfig = useRuntimeConfig();

  const apiBase = new ApiBase(runtimeConfig.public.apiBase);
  DefaultConfig.config = apiBase.getDefaultConfig();

  const headers = useApiHeaders();

  const registerApi = new RegisterApi();
  const membersApi = new MembersApi();

  async function register(registerRequestModel: RegisterRequestModel) {
    return await registerApi.registerPost({ registerRequestModel });
  }

  async function getMembersAccount() {
    return await membersApi.membersMeGet({ headers });
  }

  async function activateAccount(activateAccountApiRequestModel: ActivateAccountApiRequestModel) {
    return await membersApi.membersActivateAccountPost({
      activateAccountApiRequestModel,
    });
  }

  async function validateDebtorNumber(debtorNumber: number) {
    return await membersApi.membersValidateDebtorNumberGet({ debtorNumber });
  }

  async function validateEmailAddress({ emailAddress }: MembersValidateEmailAddressGetRequest) {
    return await membersApi.membersValidateEmailAddressGet({ emailAddress });
  }

  async function removeAccount({ refreshToken }: MembersMeDeleteRequest) {
    return await membersApi.membersMeDeleteRaw({ refreshToken }, { headers });
  }

  return {
    register,
    getMembersAccount,
    activateAccount,
    validateDebtorNumber,
    removeAccount,
    validateEmailAddress,
  };
}
