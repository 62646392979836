import { storeToRefs } from 'pinia';

export default defineNuxtRouteMiddleware(async (to) => {
  const { status, data, getSession } = useAuth();

  if (status.value !== 'authenticated') {
    return;
  }

  const runtimeConfig = useRuntimeConfig();

  const now = Date.now();
  const expiresAt = data.value?.accessTokenExpires ?? 0;

  // TODO: Use expires_in property of token and make this a percentage. If a token is valid for 20 minutes, make it expire 5 minutes (25%) before actual expiry.
  const timeLeftUntilExpiry = expiresAt - now;

  if (timeLeftUntilExpiry <= runtimeConfig.public.refreshSessionFrom) {
    const generalStore = useGeneralStore();
    const { loading } = storeToRefs(generalStore);

    loading.value = true;
    await getSession({ force: true });
    loading.value = false;
  }
});
