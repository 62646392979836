/* tslint:disable */
/* eslint-disable */
/**
 * Groenhart API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0.30.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface LocationCardApiModel
 */
export interface LocationCardApiModel {
    /**
     * 
     * @type {string}
     * @memberof LocationCardApiModel
     */
    openTodayFrom?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LocationCardApiModel
     */
    openTodayUntil?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LocationCardApiModel
     */
    openTomorrowFrom?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LocationCardApiModel
     */
    id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LocationCardApiModel
     */
    name?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof LocationCardApiModel
     */
    isFavorite?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof LocationCardApiModel
     */
    latitude?: number | null;
    /**
     * 
     * @type {number}
     * @memberof LocationCardApiModel
     */
    longitude?: number | null;
    /**
     * 
     * @type {string}
     * @memberof LocationCardApiModel
     */
    imageUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LocationCardApiModel
     */
    zipCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LocationCardApiModel
     */
    city?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LocationCardApiModel
     */
    address?: string | null;
}

/**
 * Check if a given object implements the LocationCardApiModel interface.
 */
export function instanceOfLocationCardApiModel(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function LocationCardApiModelFromJSON(json: any): LocationCardApiModel {
    return LocationCardApiModelFromJSONTyped(json, false);
}

export function LocationCardApiModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): LocationCardApiModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'openTodayFrom': !exists(json, 'openTodayFrom') ? undefined : json['openTodayFrom'],
        'openTodayUntil': !exists(json, 'openTodayUntil') ? undefined : json['openTodayUntil'],
        'openTomorrowFrom': !exists(json, 'openTomorrowFrom') ? undefined : json['openTomorrowFrom'],
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'isFavorite': !exists(json, 'isFavorite') ? undefined : json['isFavorite'],
        'latitude': !exists(json, 'latitude') ? undefined : json['latitude'],
        'longitude': !exists(json, 'longitude') ? undefined : json['longitude'],
        'imageUrl': !exists(json, 'imageUrl') ? undefined : json['imageUrl'],
        'zipCode': !exists(json, 'zipCode') ? undefined : json['zipCode'],
        'city': !exists(json, 'city') ? undefined : json['city'],
        'address': !exists(json, 'address') ? undefined : json['address'],
    };
}

export function LocationCardApiModelToJSON(value?: LocationCardApiModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'openTodayFrom': value.openTodayFrom,
        'openTodayUntil': value.openTodayUntil,
        'openTomorrowFrom': value.openTomorrowFrom,
        'id': value.id,
        'name': value.name,
        'isFavorite': value.isFavorite,
        'latitude': value.latitude,
        'longitude': value.longitude,
        'imageUrl': value.imageUrl,
        'zipCode': value.zipCode,
        'city': value.city,
        'address': value.address,
    };
}

