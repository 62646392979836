import { DefaultConfig, ContentApi, ContentOrderConfirmationGetRequest } from '@groenhart/api';
import { ApiBase } from './base.api';

export function useContentApi() {
  const runtimeConfig = useRuntimeConfig();
  const headers = useApiHeaders();

  const apiBase = new ApiBase(runtimeConfig.public.apiBase);
  DefaultConfig.config = apiBase.getDefaultConfig();

  const contentApi = new ContentApi();

  async function getContent() {
    return await contentApi.contentGet();
  }

  async function getHomeUsps() {
    return await contentApi.contentHomeUspsGet();
  }

  async function getHomeCarousel(exclTax: boolean) {
    return await contentApi.contentHomeCarouselGet({ exclTax }, { headers });
  }

  async function getUsps() {
    return await contentApi.contentUspsGet({ headers });
  }

  async function getLogo() {
    return await contentApi.contentLogoGet({ headers });
  }

  async function getOrderConfirmationPage({
    orderNumber,
    orderId,
  }: ContentOrderConfirmationGetRequest) {
    return await contentApi.contentOrderConfirmationGet({
      orderNumber,
      orderId,
    });
  }

  async function getLogoutUrl(embedded: boolean) {
    return await contentApi.contentLogoutUrlGetRaw({
      embedded,
    });
  }

  return {
    getContent,
    getHomeUsps,
    getLogo,
    getHomeCarousel,
    getOrderConfirmationPage,
    getLogoutUrl,
    getUsps,
  };
}
